<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="background-color: #fff; line-height: 60px; margin: 0; width: 100%"
    >
      <div>
        <div class="Enter-top">
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>物联电表</a-breadcrumb-item>
            <a-breadcrumb-item>缴费记录</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <a-tabs
          style="margin-left: 10px"
          default-active-key="1"
          @change="callback"
        >
          <a-tab-pane key="1" tab="报警方案"> </a-tab-pane>
          <a-tab-pane key="2" tab="电费"> </a-tab-pane>
          <a-tab-pane key="3" tab="水费"> </a-tab-pane>
          <a-tab-pane key="4" tab="物业费"> </a-tab-pane>
        </a-tabs>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap flex-d-row">
          <div style="width: 170px; border-right: 1px solid #d9d9d9">
            <div class="regionleft">区域信息</div>
            <a-tree
              :replace-fields="replaceFields"
              :tree-data="treeData"
              :value="title"
              :defaultExpandAll="true"
              @select="onSelect"
            >
            </a-tree>
          </div>
          <div style="flex: 1; padding: 24px">
            <div class="in-top">
              <a-form
                style="padding: 0 24px"
                class="ant-advanced-search-form"
                :form="fom"
                @submit="handleSearch"
              >
                <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
                  <a-col v-for="(i, index) in textlist" :key="index">
                    <a-form-item style="margin-bottom: 0" :label="i">
                      <a-input
                        style="width: 178px"
                        v-decorator="[
                          `${i}`,
                          {
                            rules: [],
                          },
                        ]"
                        :placeholder="'请输入' + i"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col class="a-col-top">
                    <a-button type="primary" html-type="submit">
                      搜索
                    </a-button>
                    <a-button
                      :style="{ marginLeft: '8px' }"
                      @click="handleReset"
                    >
                      清空
                    </a-button>
                    <!-- <a
                :style="{ marginLeft: '8px', fontSize: '12px' }"
                @click="toggle"
              >
                更多 <a-icon :type="expand ? 'up' : 'down'" />
              </a> -->
                  </a-col>
                </a-row>
              </a-form>
              <a-button>添加</a-button>
            </div>
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="modulelist"
              :rowClassName="rowClassName"
            >
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">编辑</a>
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const treeData = [
  {
    title: '0-0',
    key: '0-0',
    children: [
      {
        title: '0-0-0',
        key: '0-0-0',
        children: [
          { title: '0-0-0-0', key: '0-0-0-0' },
          { title: '0-0-0-1', key: '0-0-0-1' },
          { title: '0-0-0-2', key: '0-0-0-2' },
        ],
      },
      {
        title: '0-0-1',
        key: '0-0-1',
        children: [
          { title: '0-0-1-0', key: '0-0-1-0' },
          { title: '0-0-1-1', key: '0-0-1-1' },
          { title: '0-0-1-2', key: '0-0-1-2' },
        ],
      },
    ],
  },
]
const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '路径',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
  },
  {
    title: '模块包含子权限',
    dataIndex: 'permissions',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
// import http from "../../../http";
export default {
  data() {
    return {
      treeData,
      columns,
      replaceFields: {
        children: 'children',
        title: 'title',
        value: 'key',
      },
      visible: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      callbacks: 1,
      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      modulelist: [],
      form: {
        id: '',
      },
    }
  },
  filters: {},
  methods: {
    onSelect(selectedKeys, info) {
      console.log(selectedKeys)
      console.log(info)
    },
    callback(e) {
      this.callbacks = e
    },
    timer() {
      return setTimeout(() => {
        this.getModule()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getModule()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
}
.Enter-top {
  padding: 0 24px;
}
.in-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.regionleft {
  height: 40px;
  line-height: 40px;
  background: #fafafa;
  border-bottom: #e8e8e8 1px solid;
  padding-left: 18px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-tabs-bar {
  margin: 0;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
